/* You can add global styles to this file, and also import other style files */
@import 'ngx-toastr/toastr';
@import '@event-calendar/core/index.css';

@tailwind base;
@tailwind components;
@tailwind utilities;
@import "@angular/material/prebuilt-themes/indigo-pink.css";
@import "@ng-select/ng-select/themes/default.theme.css";
@import 'bootstrap/scss/bootstrap';


:root {
  --light-blue: #CCDFF2;
}


html, body {
  width: 100vw;
  height: 100vh;
  overflow-x: hidden;
  font-family: "Open Sans", sans-serif;
  font-optical-sizing: auto;
  font-weight: normal;
  font-style: normal;
  color: #232323;
}

.custom-grid {
  display: grid;
  justify-content: center;
  gap: 0;
  align-items: center;

  .titleGroup {

    display: contents;

    div {
      padding: 0.5rem 0.5rem;
      height: 100%;
      border-bottom: 1px solid #CBD5E1;

    }
  }

  .rowGroup {
    display: contents;

    &:hover > div {
      background-color: #f1f1f1;

      &:first-child {
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
      }

      &:last-child {
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
      }
    }

    div {
      padding: 0.5rem 0.5rem 0.5rem 0.5rem;
      height: 100%;
      display: flex;
      align-items: center;

    }

    .item-underline {
      border-bottom: 1px solid #CBD5E1;

    }
  }

}

.barth {
  &:hover {
    fill: #3A75BE !important;
  }

  &.active {
    fill: #1d7ffa !important;
  }

  &.busy:not(.active) {
    fill: #fa791d !important;
  }

  &.available {
    fill: #b7ee7e !important;
  }
}

.vis-minor {
  font-size: 11px !important;
}


// CUSTOM MATERIAL CSS

.mat-mdc-tab-labels {
  background-color: rgb(248, 249, 250);
  height: 40px;
  border-radius: 10px;
}

.mat-mdc-tab {
  height: 100% !important;
}

.mdc-tab-indicator__content {
  //border: none !important;
  border-radius: 10px !important;
  border-color: rgb(7 89 133) !important;
}

.mat-tab-indicator {

}

.mdc-tab__text-label {
  color: rgb(51 65 85) !important;
}

.ec-title {
  font-size: 1.2rem;
}

.ec-event-title {
  font-size: 1rem;
}

.ease-soft-in {
  transition-timing-function: cubic-bezier(0.42, 0, 1, 1);
}

.transition-all {
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

//.dropdown-menu {
//  width: 100%;
//}


.ng-select-container {
  border-radius: 6px;
}

.nav-outline {
  .nav-item {
    background-color: transparent;
    color: var(--bs-nav-pills-link-active-bg);
    //border: 1px solid var(--bs-nav-pills-link-active-bg);

    &:hover {
      background-color: rgba(110, 168, 254, 0.12);
    }
  }
}

.mat-step-icon-selected {
  background-color: #0D6EFDFF !important;
}

.mat-step-icon-state-edit {

  background-color: rgba(89, 145, 215, 0.89) !important;

}

.mat-horizontal-stepper-header {
  height: 46px !important;
  border-radius: 10px;
}

.mat-step-header:hover:not([aria-disabled]) {
  background-color: transparent !important;
}

.btn {
  cursor: pointer !important;
}

input::-webkit-calendar-picker-indicator {
  cursor: pointer;
}

input.ng-invalid.ng-touched {
  border: #e02727 1px solid !important;
  box-shadow: 0 0 0 0.25rem rgba(224, 39, 39, 0.25);
}
